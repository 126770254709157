
































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { GetCenter, CreatorList } from "@/request/common";
import { ZuzhijiagouList } from "@/request/manage";
import {
  GetTonguePicLists,
  getBiaoqianList,
  PostSources,
  PostCreators,
} from "@/request/research";
import Pagination from "@/components/paginaiton.vue";
import SearchZhuanbing from "@/components/search-zhuanbing.vue";
import SearchJigou from "@/components/search-jigou.vue";
import SearchCreator from "@/components/search-creator2.vue";
import SearchSource from "@/components/search-source.vue";
import PicList from "../components/picList.vue";
import TextList from "../components/textList.vue";
@Component({
  components: {
    Pagination,
    SearchZhuanbing,
    SearchJigou,
    SearchCreator,
    SearchSource,
    PicList,
    TextList,
  },
})
export default class Name extends mixins(listMixin) {
  private listType: any = "pic";
  private centers: any = [];
  private institutions: any = [];
  private creators: any[] = [];
  private tongues: any[] = [];
  private sources: any[] = [];
  private labels: any[] = [];
  private IsNomessage: boolean = false;
  // 选择舌图
  private selectedTongues: any[] = [];
  private hasSelectAll: boolean = false;
  private ifShowBiaozhu: boolean = false;
  /**
   * @description 获得可选的标签列表
   */
  private fetchBiaoqianList() {
    return new Promise((resolve, reject) => {
      getBiaoqianList(this)
        .then((data: any) => {
          this.labels = data;
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 跳转智能标注
   */
  private jumpBiaozhu() {
    this.$router.push("/main/research/tongue/list");
  }
  /**
   * @description 获得可选专病列表
   */
  private fetchCenters() {
    GetCenter(this).then((data: any) => {
      this.centers = data;
      this.centers.unshift({ project_id: "全部", 项目名称: "全部专病" });
      this.fetchHospitals();
    });
  }
  private remoteSourceMethod(e: any) {
    this.fetchSource(e);
  }
  private remoteCreatorMethod(e: any) {
    this.getCreatorsList(e);
  }
  /**
   * @description 获得来源
   */
  private fetchSource(val?: any) {
    const params: any = {
      keywords: val || "",
    };
    PostSources(this, params).then((data: any) => {
      this.sources = data;
      this.getCreatorsList();
      // 循环当前选项，如果不在列表中，需要插入进去
      if (this.filters.sources && this.filters.sources.length > 0) {
        this.filters.sources.forEach((ele: any) => {
          const index = this.sources.findIndex((val: any) => {
            return ele.source_id === val.source_id;
          });
          if (index == -1) {
            this.sources.unshift(ele);
          }
        });
      }
    });
  }
  /**
   * @description
   */
  private sourceChange(e: any) {
    this.filters.sources = e;
    // 重置创建人
    this.filters.user_ids = [];
    // 重置创建人选项
    this.getCreatorsList();
    // 搜索
    this.search();
  }
  /**
   * @description 获得可选机构列表
   */
  private fetchHospitals() {
    const params: any = {
      params: {
        project_id: this.filters.project_id,
      },
    };
    if (this.filters.project_id == "全部") {
      params.params.project_id = "";
    }
    ZuzhijiagouList(this, params).then((data: any) => {
      this.institutions = data;
      this.getCreatorsList();
    });
  }
  /**
   * @description 获得可选创建者列表
   */
  private getCreatorsList(e?: any) {
    const params: any = {
      ids: [],
      keywords: e || "",
    };
    if (this.filters.sources && this.filters.sources.length > 0) {
      this.filters.sources.forEach((ele: any) => {
        params.ids.push(ele.source_id);
      });
    }
    PostCreators(this, params).then((data: any) => {
      this.creators = data;
      if (this.filters.user_ids && this.filters.user_ids.length > 0) {
        this.filters.user_ids.forEach((ele: any) => {
          const index = this.creators.findIndex((val: any) => {
            return ele.create_id === val.create_id;
          });
          if (index == -1) {
            this.creators.unshift(ele);
          }
        });
      }
    });
  }

  /**
   * @description 改变列表展示的形式
   */
  private changeListType(type: any) {
    this.listType = type;
  }
  /**
   * @description 选择专病改变
   */
  private centersChange(e: any) {
    this.filters.project_id = e;
    // 重置机构
    this.filters.institution_id = "";
    // 重置机构选项
    this.fetchHospitals();
    // 重置创建人
    this.filters["创建人"] = "";
    this.filters.user_ids = [];
    // 重置创建人选项
    this.getCreatorsList();
    // 搜索
    this.search();
  }
  /**
   * @description 选择机构改变
   */
  private institutionChange(e: any) {
    this.filters.institution_id = e;
    // 重置创建人
    this.filters["创建人"] = "";
    this.filters.user_ids = [];
    // 重置创建人选项
    this.getCreatorsList();
    // 搜索
    this.search();
  }
  /**
   * @description 选择创建人改变
   */
  private creatorChange(e: any) {
    this.filters["创建人"] = e;
    // 搜索
    this.search();
  }
  /**
   * @description 改变排序顺序
   */
  private changeOrderValue() {
    this.filters.orderValue = this.filters.orderValue === 0 ? 1 : 0;
    this.search();
  }
  /**
   * @description 获得舌图图片列表
   */
  getList() {
    const params: any = {
      // project_id: this.filters.project_id,
      标注状态: this.filters["标注状态"],
      search: this.filters.search,
      // institution_id: this.filters.institution_id,
      creators: [],
      source_ids: [],
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
      orderField: this.filters.orderField,
      orderValue: this.filters.orderValue,
      tags: this.filters.tags,
    };
    if (this.filters["标注状态"] == "全部") {
      params["标注状态"] = "";
    }
    if (this.filters.sources && this.filters.sources.length > 0) {
      this.filters.sources.forEach((ele: any) => {
        params.source_ids.push(ele.source_id);
      });
    }
    if (this.filters.user_ids && this.filters.user_ids.length > 0) {
      this.filters.user_ids.forEach((ele: any) => {
        params.creators.push(ele.create_id);
      });
    }
    localStorage.setItem("biduiFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetTonguePicLists(this, params)
      .then((data: any) => {
        loading.close();
        if (data.tongues.length === 0) {
          this.IsNomessage = true;
        } else {
          this.IsNomessage = false;
        }
        data.tongues.forEach((tongue: any) => {
          const index = this.selectedTongues.findIndex((i: any) => {
            return i.tongue_id === tongue.tongue_id;
          });
          if (index === -1) {
            tongue.selected = false;
          } else {
            tongue.selected = true;
          }
        });
        this.$set(this, "tongues", data.tongues);
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 取消选择的舌图
   */
  private cancelSelectedTongue(tongue: any) {
    const index = this.selectedTongues.findIndex((val: any) => {
      return tongue.tongue_id === val.tongue_id;
    });
    if (index !== -1) {
      this.selectedTongues.splice(index, 1);
      localStorage.setItem(
        "selectedTongues",
        JSON.stringify(this.selectedTongues)
      );
      this.tongues.forEach((t: any) => {
        const j = this.selectedTongues.findIndex((i) => {
          return i.tongue_id === t.tongue_id;
        });
        if (j === -1) {
          t.selected = false;
        } else {
          t.selected = true;
        }
      });
    }
    this.$forceUpdate();
  }
  /**
   * @description 开始比对
   */
  private startBidui() {
    if (this.selectedTongues.length <= 1) {
      this.$message.warning("请至少选择两张舌图");
      return;
    }
    if (this.selectedTongues.length > 6) {
      this.$message.warning("最多选择6张舌图");
      return;
    }
    localStorage.setItem(
      "selectedTongues",
      JSON.stringify(this.selectedTongues)
    );
    localStorage.setItem("biduiTime", moment().format("yyyy-MM-DD HH:mm:ss"));
    this.$router.push("/main/research/tongue/bidui-preview");
  }
  /**
   * @description 全选
   */
  private selectAllData() {
    this.tongues.forEach((tongue: any) => {
      if (!tongue.selected) {
        tongue.selected = true;
        const index = this.selectedTongues.findIndex((i: any) => {
          return i.tongue_id === tongue.tongue_id;
        });
        if (index === -1) {
          this.selectedTongues.push(tongue);
          localStorage.setItem(
            "selectedTongues",
            JSON.stringify(this.selectedTongues)
          );
        }
      }
    });
    this.hasSelectAll = true;
  }
  /**
   * @description 全不选
   */
  private cancelSelectAll() {
    this.tongues.forEach((tongue: any) => {
      if (tongue.selected) {
        const index = this.selectedTongues.findIndex((i: any) => {
          return i.tongue_id === tongue.tongue_id;
        });
        if (index !== -1) {
          tongue.selected = false;
          this.selectedTongues.splice(index, 1);
          localStorage.setItem(
            "selectedTongues",
            JSON.stringify(this.selectedTongues)
          );
        }
      }
    });
    this.hasSelectAll = false;
  }
  /**
   * @description 切换一个舌图的选中状态
   */
  private triggerOneTongue(tongue: any) {
    tongue.selected = !tongue.selected;
    const index = this.selectedTongues.findIndex((i: any) => {
      return i.tongue_id === tongue.tongue_id;
    });
    if (tongue.selected) {
      if (index === -1) {
        this.selectedTongues.push(tongue);
        localStorage.setItem(
          "selectedTongues",
          JSON.stringify(this.selectedTongues)
        );
      }
    } else {
      if (index !== -1) {
        this.selectedTongues.splice(index, 1);
        localStorage.setItem(
          "selectedTongues",
          JSON.stringify(this.selectedTongues)
        );
      }
    }
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.project_id = "";
    this.filters.institution_id = "";
    this.filters["创建人"] = "";
    this.filters.user_ids = [];
    this.filters.sources = [];
    this.filters.tags = [];
    this.filters["标注状态"] = "";
    this.filters.orderValue = 0;
    this.filters.orderField = "创建时间";
    this.filters.page_count = 12;
    const d = localStorage.getItem("biduiFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    const arr = localStorage.getItem("selectedTongues");
    if (arr) {
      this.selectedTongues = JSON.parse(arr);
    }
    // this.fetchCenters();
    this.fetchSource();
    this.fetchBiaoqianList();
    this.getList();
  }
}
